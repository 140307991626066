const Tab = (props: any) => {
	const onClick = () => {
		const { label, onClick } = props;
		onClick(label);
	};

	return (
		<li className={`crmls-tab flex-inline ${props.activeTab === props.label ? "active" : ""}`} onClick={onClick}>
			{props.label}{props.badge ? props.badge.badge : ""}
		</li>
	);
};

export default Tab;
