
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';

const reactPlugin: any = new ReactPlugin();
const clickAnalyticsInstance: any = new ClickAnalyticsPlugin();

const initAppInsights = (browserHistory: any) => {
    const appInsights: any = new ApplicationInsights({
        config: {
            connectionString : process.env.REACT_APP_AI_CONNECTION_STRING,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            autoTrackPageVisitTime: true,
            disableAjaxTracking: true,
            loggingLevelTelemetry: 2,
            extensions: [reactPlugin, clickAnalyticsInstance],
            extensionConfig: {
                [reactPlugin.identifier]: {
                    history: browserHistory
                },
                [clickAnalyticsInstance.identifier]: {
                    autoCapture: true
                }
            }
        }
    });

    appInsights.loadAppInsights();
}

export { initAppInsights, reactPlugin };